import React from 'react';
import ChatbotComponent from './ChatbotComponent';

const App = () => {
  return (
      <div>
        <h1>Mon Application Chatbot</h1>
        <ChatbotComponent />
      </div>
  );
};

export default App;
