// src/dustService.js
import axios from 'axios';

const API_URL = '/proxy';

const createConversation = async (message) => {
    try {
        const response = await axios.post(API_URL, {
            visibility: "unlisted",
            title: null,
            message: {
                content: message,
                mentions: [{ configurationId: "dust" }],
                context: {
                    timezone: "Europe/Paris",
                    username: "peter",
                    email: null,
                    fullName: "Peter Parker",
                    profilePictureUrl: "https://dust.tt/static/systemavatar/helper_avatar_full.png"
                }
            }
        });

        const conversationId = response.data.conversation.sId;
        let agentMessage;
        let attempts = 0;

        while (!agentMessage && attempts < 5) {  // Limiter le nombre de tentatives à 10
            await new Promise(resolve => setTimeout(resolve, 1500));  // Attendre 1 seconde
            const updatedConversation = await axios.get(`${API_URL}/${conversationId}`);
            const messages = updatedConversation.data.conversation.content.flat();
            agentMessage = messages.find(message => message.type === 'agent_message' && message.content);
            attempts++;
        }

        return agentMessage ? agentMessage.content : 'Pas de réponse de l\'agent après plusieurs tentatives.';
    } catch (error) {
        console.error('Error querying Dust.tt:', error);
        return 'Désolé, il y a eu une erreur en essayant de communiquer avec Dust.tt.';
    }
};

export default createConversation;
