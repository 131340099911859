// src/ChatbotComponent.js
import React from 'react';
import ChatBot from 'react-simple-chatbot';
import styled, { ThemeProvider } from 'styled-components';
import createConversation from './dustService';

const theme = {
    background: '#f5f8fb',
    fontFamily: 'Helvetica Neue',
    headerBgColor: '#00B2A9',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#00B2A9',
    botFontColor: '#fff',
    userBubbleColor: '#fff',
    userFontColor: '#4a4a4a',
};

const DustQuery = ({ steps, triggerNextStep }) => {
    React.useEffect(() => {
        const fetchAnswer = async () => {
            const answer = await createConversation(steps.query.value);
            triggerNextStep({ value: answer, trigger: '4' });
        };
        fetchAnswer();
    }, [steps, triggerNextStep]);

    return null;
};

const steps = [
    {
        id: '1',
        message: 'Bonjour! Comment puis-je vous aider?',
        trigger: 'query',
    },
    {
        id: 'query',
        user: true,
        trigger: 'dust-query',
    },
    {
        id: 'dust-query',
        component: <DustQuery />,
        waitAction: true,
        trigger: '4',
    },
    {
        id: '4',
        message: '{previousValue}',
        end: true,
    },
];

const ChatContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
`;

const ChatbotComponent = () => {
    return (
        <ThemeProvider theme={theme}>
            <ChatContainer>
                <ChatBot steps={steps} />
            </ChatContainer>
        </ThemeProvider>
    );
};

export default ChatbotComponent;